<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!--Filtros-->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            v-if="permisoEditar === 1"
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            title="Agregar Nuevo"
            @click="open_window(null)"
            >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor:default;">  
            <div class="mb-1" left>
              <v-icon>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Código
                  <v-text-field
                    v-model="filtro.articuloCodigo"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                    >
                  </v-text-field>
                </v-col>
                <v-col  cols="12" sm="6" md="3" class="py-1">
                  Original
                  <v-autocomplete
                    v-model="filtro.tipo"
                    item-text="tipo_nombre"
                    item-value="tipo_codigo"
                    tabindex="1"
                    :items="tipos"
                    hide-details
                    clearable
                    outlined
                    dense
                    >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Marca
                  <v-autocomplete
                    v-model="filtro.marca"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="marcas"
                    hide-details
                    clearable
                    outlined
                    dense
                    @change="getModelos()">
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="3" class="py-1">
                  Modelo
                  <v-autocomplete
                    v-model="filtro.modelo"
                    item-text="modelo_nombre"
                    item-value="modelo_codigo"
                    tabindex="1"
                    :items="modeloSelect"
                    hide-details
                    clearable
                    outlined
                    dense
                    >
                  </v-autocomplete>
                </v-col>
              <!-- </v-row>
              <v-row class="mt-6"> -->
                <v-col  cols="12" sm="6" md="3" class="py-1">
                  Rubro
                  <v-autocomplete
                    v-model="filtro.rubro"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="rubros"
                    hide-details
                    clearable
                    outlined
                    dense
                    @change="getCategorias()">
                  </v-autocomplete>
                </v-col>
                <v-col  cols="12" sm="6" md="3" class="py-1">
                  Categoría
                  <v-autocomplete
                    v-model="filtro.categoria"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="catSelect"
                    hide-details
                    clearable
                    outlined
                    dense >
                  </v-autocomplete>
                </v-col>
                <v-col  cols="12" sm="6" md="3" class="py-1">
                  Estado
                  <v-autocomplete
                    v-model="filtro.estado"
                    item-text="estado_nombre"
                    item-value="estado_codigo"
                    tabindex="1"
                    :items="estados"
                    hide-details
                    clearable
                    outlined
                    dense
                    >
                  </v-autocomplete>
                </v-col>
                <v-col  cols="12" sm="6" md="3" class="py-1">
                  <BtnFiltro
                      :loading="load"
                      clase="mt-0"
                      @clear="limpiar()"
                    />
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <!-- DATATABLE -->
    <v-col cols="12">
      <v-data-table
        class="cebra elevation-2 mt-2"
        sort-by="deposito_nombre"
        :headers="headers"
        :items="articulosDatos"
        :loading="load"
        :search="search"
        :footer-props="{'items-per-page-options':[10, 15, 50, 100]}"
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3" xl="2">
              <SearchDataTableVue
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.acciones`]="{ item }">
          <v-icon
            color="info"
            title="Ver"
            class="mr-2"
            small
            @click="open_window(item)">
            fas fa-eye
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table> 
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import BtnFiltro from '../../components/util/BtnFiltro'
import SearchDataTableVue from '../../components/util/SearchDataTable.vue'
import { order_list_by } from '../../util/utils'

export default {
  name:'ABMdeArticulos',
  data(){
    return{
      panel: 0,
      load: false,
      search: '',
      filtro: {
        articuloCodigo: null,
        marca: null,
        modelo: null,
        rubro: null,
        categoria: null,
        estado: null,
        tipo: null,
      },
      catSelect: [],
      modeloSelect: [],
      modelos: [],
      permisoEditar: null,
      estados: [],
      headers: [
        { text: 'Codigo', value: 'articulo_codigo'},
        { text: 'Nombre', value: 'articulo_nombre' },
        { text: 'Marca', value: 'marca_nombre' },
        { text: 'Modelo', value: 'modelo_nombre' },
        { text: 'Rubro', value: 'rubro_nombre' },
        { text: 'Categoría', value: 'categoria_nombre' },
        { text: 'Estado', value: 'estado_nombre' },
        { text: 'Acciones',value: 'acciones', align: 'center', sortable: false, filterable: false}
      ],
      articulosDatos: [],
      tipos: [
        {tipo_codigo: 1, tipo_nombre: 'SI'},
        {tipo_codigo: 0, tipo_nombre: 'NO'},
        {tipo_codigo: null, tipo_nombre: 'TODOS'},
      ]
    }
  },
  async created(){
    this.$store.state.loading = true;
    //Rubro
    this.$store.dispatch('rubros/get_rubros_habilitados');
    //Categorias 
    this.$store.dispatch('categorias/get_categorias_habilitadas');
    //Marca
    this.$store.dispatch('marcas/get_marcas_habilitadas');
    //Modelos
    await this.$store.dispatch('articulos/initABMArticulos')
      .then(res => {
        this.estados = res.estados;
        this.modelos = res.modelos;
        this.permisoEditar = res.permiso_editar;
      })
      this.$store.state.loading = false;
  },
  components:{
    BtnFiltro,
    SearchDataTableVue
  },
  computed:{
    ...mapState('rubros',['rubros']),
    ...mapState('categorias',['categorias']),
    ...mapState('marcas',['marcas']),
    //...mapState('articulos',['art_modelos',/*'articulos'*/])
  },
  methods:{
    open_window(item){
      let path = '/nuevo-articulo'
      if (item) {
        path = `/articulo/${item.articulo_codigo}`
      }
      const routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    },
    getCategorias(){
      if(this.filtro.rubro !== null){
        this.catSelect = this.categorias.filter(elem => elem.rubro_codigo === this.filtro.rubro);
      }else{
        this.filtro.categoria = null;
        this.catSelect = [];
      }
    },
    async getModelos(){
      if(this.filtro.marca !== null){
        this.modeloSelect = this.modelos.filter(elem => elem.marca_codigo === this.filtro.marca);
        order_list_by(this.modeloSelect, 'modelo_nombre')
      }else{
        this.filtro.modelo = null;
        this.modeloSelect = []
      }
    },
    async buscar(){
      this.search = '';
      this.load = true;
      
      await this.$store.dispatch('articulos/getArticulos',this.filtro)
        .then(res => {
          this.articulosDatos = res.articulos;
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      
        this.load = false;
    },
    limpiar(){
      this.search= '';
      this.filtro={
        articuloCodigo: null,
        marca: null,
        modelo: null,
        rubro: null,
        categoria: null,
        estado: null,
        tipo: null,
      };
    }
  }
}
</script>

<style>

</style>